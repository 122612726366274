/* Style pour le conteneur principal */
.container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

/* Style pour le titre */
h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

/* Style pour le fieldset */
.fieldset {
  border: 2px solid #007BFF;
  border-radius: 4px;
  padding: 20px;
}

/* Style pour le formulaire */
.form {
  display: flex;
  flex-direction: column;
}

/* Style pour le label */
.form label {
  font-size: 16px;
  margin-bottom: 10px;
}

/* Style pour l'input */
.form input[type="number"] {
  padding: 10px;
  font-size: 16px;
  border: none;
  border-bottom: 2px solid #007BFF;
  margin-bottom: 10px;
}

/* Style pour le bouton "Générer" */
.form button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  align-self: flex-end; /* Aligner le bouton à droite */
}

/* Style pour le message de chargement */
.loading-message {
  font-size: 18px;
  text-align: center;
  margin: 20px;
}

/* Style pour le popup de message */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 20px;
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Style pour le message de succès */
.popup.success {
  border-color: #28a745;
  background-color: #d4edda;
  color: #155724;
}

/* Style pour le message d'erreur */
.popup.error {
  border-color: #dc3545;
  background-color: #f8d7da;
  color: #721c24;
}

/* Style pour le message dans le popup */
.popup-message {
  font-size: 16px;
  margin-bottom: 20px;
}

/* Style pour le bouton "Fermer" du popup */
.popup-close {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
