.card {
    border: 1px solid #e1e1e1;
    padding: 20px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: inline-block;
    min-width: 200px;
    text-align: center;
  }
  
  .expired {
    background-color: #ff6961;
    color: #fff;
  }
  
  .active {
    background-color: #77dd77;
    color: #fff;
  }
  
  .unused {
    background-color: #007bff;
    color: #fff;
  }
  
  .card h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .card p {
    font-size: 24px;
    font-weight: bold;
  }
  