/* Styles pour le formulaire de connexion */
form {
    max-width: 300px;
    margin: 0 auto;
    padding: 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}

h2 {
    text-align: center;
    color: #333;
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
}

input[type="email"],
input[type="password"] {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px; /* Ajustement de la marge inférieure */
    border: 1px solid #ccc;
    border-radius: 10px;
}

button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}
