/* Styles pour l'overlay semi-transparent */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
  }


  
  /* Styles pour le contenu du modal */

 .modal {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%; /* Ajustez la largeur du popup selon vos besoins */
    max-width: 400px; /* Limitez la largeur maximale du popup pour assurer une bonne lisibilité */
    position: relative; /* Ajout de la position relative pour la croix de fermeture */
  }

  

  
  
  .button-container {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;
  }

  /* Styles pour le bouton de fermeture du modal */
  .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  

  input[type="text"],
input[type="email"],
select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 16px;
  width: 100%;
}




